<template>
  <div>
    <div class="list-title">
      基本配置
    </div>

    <el-form :model="form" label-position="top" size="small" ref="form">
      <el-form-item
        :label="$t('name')"
        prop="name"
        :rules="[
          { required: true, message: $t('rules.require'), trigger: 'blur' },
          {
            pattern: /^[a-z0-9]([-a-z0-9]*[a-z0-9])?(\.[a-z0-9]([-a-z0-9]*[a-z0-9])?)*$/,
            message: this.$t('rules.pattern'),
            trigger: 'blur'
          }
        ]"
      >
        <el-input v-model="form.name" :disabled="type != 'add'"></el-input>
      </el-form-item>

      <el-form-item
        :label="$t('region')"
        prop="regionID"
        :rules="{ required: true, message: $t('rules.require'), trigger: 'blur' }"
      >
        <el-input v-model="form.regionID"></el-input>
      </el-form-item>

      <el-form-item
        label="AccessKey"
        v-if="['ProviderAWS', 'ProviderALiCloud', 'ProviderTencent'].includes(form.kind)"
        prop="accessKey"
        :rules="{ required: true, message: $t('rules.require'), trigger: 'blur' }"
      >
        <el-input v-model="form.accessKey"></el-input>
      </el-form-item>

      <el-form-item
        label="SecretAccessKey"
        v-if="['ProviderAWS', 'ProviderALiCloud', 'ProviderTencent'].includes(form.kind)"
        prop="secretAccessKey"
        :rules="{ required: true, message: $t('rules.require'), trigger: 'blur' }"
      >
        <el-input v-model="form.secretAccessKey"></el-input>
      </el-form-item>

      <el-form-item
        label="Account"
        v-if="['ProviderHuawei'].includes(form.kind)"
        prop="account"
        :rules="{ required: true, message: $t('rules.require'), trigger: 'blur' }"
      >
        <el-input v-model="form.account"></el-input>
      </el-form-item>

      <el-form-item
        label="AccountPassword"
        v-if="['ProviderHuawei'].includes(form.kind)"
        prop="accountPassword"
        :rules="{ required: true, message: $t('rules.require'), trigger: 'blur' }"
      >
        <el-input v-model="form.accountPassword" type="password" show-password></el-input>
      </el-form-item>

      <el-form-item
        label="DomainName"
        v-if="['ProviderHuawei'].includes(form.kind)"
        prop="domainName"
        :rules="{ required: true, message: $t('rules.require'), trigger: 'blur' }"
      >
        <el-input v-model="form.domainName"></el-input>
      </el-form-item>

      <el-form-item
        label="ProjectID"
        v-if="['ProviderHuawei'].includes(form.kind)"
        prop="projectID"
        :rules="{ required: true, message: $t('rules.require'), trigger: 'blur' }"
      >
        <el-input v-model="form.projectID"></el-input>
      </el-form-item>

      <el-form-item
        label="ProjectName"
        v-if="['ProviderHuawei'].includes(form.kind)"
        prop="projectName"
        :rules="{ required: true, message: $t('rules.require'), trigger: 'blur' }"
      >
        <el-input v-model="form.projectName"></el-input>
      </el-form-item>

      <el-form-item :label="$t('JSONSecret')" v-if="this.form.kind == 'ProviderGCP'">
        <editor v-model="form.gcpConfJson" @init="jsonEditorInit" lang="json" theme="cobalt" width="100%" height="200">
        </editor>
      </el-form-item>

      <el-form-item label="KubeConfig" v-if="this.form.kind == 'ProviderKubeconfig'">
        <editor v-model="form.kubeconfig" @init="yamlEditorInit" lang="yaml" theme="cobalt" width="100%" height="300">
        </editor>
      </el-form-item>

      <el-form-item
        :label="$t('Cluster')"
        v-if="this.form.kind != 'ProviderKubeconfig'"
        prop="cluster"
        :rules="{ required: true, message: $t('rules.require'), trigger: 'change' }"
      >
        <el-select
          v-if="clusterSelect"
          v-model="form.cluster"
          filterable
          style="width: 500px;"
          @focus="getClusterList"
          :loading="clusterLoading"
        >
          <template #prefix v-if="clusterLoading">
            <i class="el-icon-loading" style="margin-left: 4px;"></i>
          </template>
          <el-option v-for="item in clusterList" :key="item.useUnique" :label="item.useUnique" :value="item.useUnique">
          </el-option>
        </el-select>

        <el-input v-else v-model="form.cluster" style="width: 500px;"></el-input>

        <el-button type="primary" style="margin-left: 10px;" @click="clusterSelect = !clusterSelect">
          {{ clusterSelect ? "手动输入" : "下拉选择" }}
        </el-button>
      </el-form-item>

      <el-form-item
        :label="$t('Cluster')"
        v-else
        prop="cluster"
        :rules="{ required: true, message: $t('rules.require'), trigger: 'blur' }"
      >
        <el-input v-model="form.cluster"></el-input>
      </el-form-item>

      <el-form-item :label="$t('handle.clusterTest')">
        <el-button type="primary" @click="clusterTest" size="small" icon="el-icon-setting" plain :loading="testLoading">
          <span v-if="!testLoading">{{ $t("handle.test") }}</span>
          <span v-else>{{ $t("handle.testing") }}</span>
        </el-button>

        <el-tag
          v-if="testContainerShow"
          effect="dark"
          :type="testStatus ? 'success' : 'error'"
          style="margin-left: 10px;"
        >
          {{ testStatus ? "连接正常" : "连接失败" }}
        </el-tag>
      </el-form-item>
    </el-form>

    <slot name="button"></slot>
  </div>
</template>

<script>
import { clusterListByProvider, clusterTest } from "api/app";
export default {
  props: {
    form: Object,
    type: String
  },

  components: {
    editor: require("vue2-ace-editor")
  },

  data() {
    return {
      clusterList: [],
      testLoading: false,
      testContainerShow: false,
      testStatus: false,
      clusterLoading: false,
      clusterSelect: true
    };
  },

  methods: {
    yamlEditorInit: function() {
      require("brace/ext/searchbox");
      require("brace/mode/yaml");
      require("brace/theme/cobalt");
    },

    jsonEditorInit: function() {
      require("brace/ext/searchbox");
      require("brace/mode/json");
      require("brace/theme/cobalt");
    },

    async getClusterList() {
      this.clusterLoading = true;
      this.form.zoneID = this.form.regionID;

      let response = await clusterListByProvider(this.form);

      this.clusterLoading = false;
      if (response.code === 0) {
        this.clusterList = response.data.item;
      }
    },

    clusterTest() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.testLoading = true;
          this.testContainerShow = false;
          this.form.zoneID = this.form.regionID;
          clusterTest(this.form).then(response => {
            this.testLoading = false;
            if (response.code === 0) {
              this.testContainerShow = true;
              this.testStatus = response.data;
            }
          });
        }
      });
    }
  }
};
</script>

<style scoped>
.err-message {
  line-height: 1;
  font-size: 12px;
  color: red;
}
</style>
